const baseStyles = [
  "color: rgb(50, 50, 50)",
  "font-size: 1.2rem",
  'font-family: "Nunito", "Avenir", "Helvetica", "sans-serif"',
]

const headerStyles = [
  "font-size: 2rem",
  'font-family: "Nunito", "Avenir", "Helvetica", "sans-serif"',
]

const pStyles = ["line-height: 1.5"]

const codeStyles = [
  "display: block",
  "padding: 3px",
  "border-radius: 2px",
  "border: 1px solid #AAAAAA",
  "font-family: monospace",
  "color: #FF4D4D",
  "background-color: #F3F3F3",
]

function renderStyles() {
  return Array.from(arguments)
    .map(style => style.join(";"))
    .join(";")
}

if (typeof window === "undefined") {
  window = {}
}

let answer = ""

window.say = val => {
  answer = val
  prompt()
}

const prompt = () => {
  if (answer) {
    let response = `%cYou said ${answer}`
    console.log(response, renderStyles(baseStyles, pStyles))
  } else {
    console.log(
      "%cWhat would you like to do?\n Example:",
      renderStyles(baseStyles, pStyles)
    )
    console.log('%csay("hire")', renderStyles(baseStyles, codeStyles))
  }
}

const welcome = () => {
  console.log("%cWelcome to Mulch", renderStyles(baseStyles, headerStyles))
  // prompt()
  return null
}

module.exports = welcome
